@import "../scss/variables";

a {
	color: $primary-1;
	&:hover {
		color: $primary-1;
	}
}
.bg-primary {
	background: $primary-1 !important;
}
a.bg-primary {
	&:hover, &:focus {
		background-color: #5a51c7 !important;
	}
}
button.bg-primary {
	&:hover, &:focus {
		background-color: #5a51c7 !important;
	}
}
/*--- gradient-backgrounds --*/

.bg-primary-gradient {
	background: $primary-gradient-1 !important;
}
a.bg-primary-gradient {
	&:hover, &:focus {
		background-color: $primary-1 !important;
	}
}
button.bg-primary-gradient {
	&:hover, &:focus {
		background-color: $primary-1 !important;
	}
}
.border-primary {
	border-color: $primary-1 !important;
}
.text-primary {
	color: $primary-1 !important;
}
a.text-primary {
	&:hover, &:focus {
		color: #4e12b6 !important;
	}
}
.table-primary {
	background-color: #d2cdf9;
	> {
		th, td {
			background-color: #d2cdf9;
		}
	}
}
.table-hover .table-primary:hover {
	background-color: #b7cded;
	> {
		td, th {
			background-color: #b7cded;
		}
	}
}
.select2-container--default {
	&.select2-container--focus .select2-selection--multiple {
		border-color: $primary-1;
	}
	.select2-selection--multiple .select2-selection__choice {
		background-color: $primary-1 !important;
		border: 1px solid $primary-1 !important;
	}
}
.btn-primary {
	color: $white !important;
	background: $primary-1 !important;
	border-color: $primary-1 !important;
	&:hover {
		color: $white;
		background: $primary-1;
		border-color: $primary-1;
		opacity: 0.8;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px $primary-06;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $primary-1;
		border-color: $primary-1;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $primary-1;
			border-color: $primary-1;
		}
	}
}
.show>.btn-primary.dropdown-toggle {
	color: $white;
	background-color: $primary-1;
	border-color: $primary-1;
}
.btn-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px $primary-06;
	}
}
.show>.btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $primary-06;
}
.btn-primary-light {
	color: $primary-1 !important;
	background: $primary-02 !important;
	border-color: $primary-02;
	&:hover {
		color: $white !important;
		background-color: $primary-1 !important;
		border-color: $primary-1;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px $primary-06;
	}
}
.btn-primary-gradient {
	color: $white;
	background: $primary-gradient-1 !important;
	border-color: $primary-1 !important;
	box-shadow: 0 5px 10px $primary-03;
	&:hover {
		color: $white;
		background-color: $primary-1;
		border-color: $primary-1;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px $primary-03;
	}
	&.disabled, &:disabled {
		color: $white;
		background-color: $primary-1;
		border-color: $primary-1;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white;
			background-color: $primary-1;
			border-color: $primary-1;
		}
	}
}
.show>.btn-primary-gradient.dropdown-toggle {
	color: $white;
	background-color: $primary-1;
	border-color: $primary-1;
}
.btn-primary-gradient:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px $primary-06;
	}
}
.show>.btn-primary-gradient.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px $primary-06;
}
.btn-outline-primary {
	color: $primary-1 !important;
	background: transparent;
	background-image: none;
	border-color: $primary-1;
	&:hover {
		color: $white !important;
		background: $primary-1;
		border-color: $primary-1;
	}
	&:focus, &.focus {
		box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
	}
	&.disabled, &:disabled {
		color: $primary-1;
		background-color: transparent;
	}
	&:not(:disabled):not(.disabled) {
		&:active, &.active {
			color: $white !important;
			background-color: $primary-1;
			border-color: $primary-1;
		}
	}
}
.show>.btn-outline-primary.dropdown-toggle {
	color: $white;
	background-color: $primary-1;
	border-color: $primary-1;
}
.btn-outline-primary:not(:disabled):not(.disabled) {
	&:active:focus, &.active:focus {
		box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
	}
}
.show>.btn-outline-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 2px rgba(98, 89, 202, 0.1);
}
.btn-link {
	color: $primary-1;
	&:hover {
		color: $primary-1;
	}
}
.drop-icon-wrap .drop-icon-item:hover {
	color: $primary-1;
}
.dropdown-item {
	&:hover, &:focus, &.active, &:active {
		color: $primary-1;
	}
}
.timeline__item:after {
	border: 6px solid $primary-1;
}
.custom-control-input:checked~.custom-control-label::before {
	color: $white;
	background-color: $primary-1;
	border-color: $primary-1;
}
.custom-checkbox .custom-control-input {
	&:checked~.custom-control-label::before, &:indeterminate~.custom-control-label::before, &:disabled:checked~.custom-control-label::before {
		background-color: $primary-1;
	}
}
.custom-radio .custom-control-input {
	&:checked~.custom-control-label::before {
		background-color: $primary-1;
		border-color: $primary-1;
	}
	&:disabled:checked~.custom-control-label::before {
		background-color: $primary-1;
	}
}
.form-select:focus, .form-file-input:focus~.form-file-label::after {
	border-color: none;
}
.form-file-label::after {
	background-color: $primary-1;
}
.custom-range {
	&::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb {
		background-color: $primary-1;
	}
}
.nav-pills {
	.nav-link.active, .show>.nav-link {
		background-color: $primary-1;
	}
}
.page-link:hover {
	color: $primary-1;
}
.page-item.active .page-link {
	color: $white;
	background-color: $primary-1;
	border-color: $primary-1;
}
.range.range-primary {
	input[type="range"] {
		&::-webkit-slider-thumb, &::-moz-slider-thumb {
			background-color: $primary-1;
		}
	}
	output {
		background-color: $primary-1;
	}
	input[type="range"] {
		outline-color: $primary-1;
	}
}
.panel.price>.panel-heading {
	background: $primary-1;
}
.nav-tabs .nav-item1 .nav-link {
	&:hover:not(.disabled), &.active {
		background: $primary-1;
	}
}
.pop-primary {
	color: $primary-1;
}
.heading-primary {
	background-color: $primary-1;
	color: $white;
	padding: 5px;
}
.breadcrumb-item.active {
	color: #f5f3f9;
}
.breadcrumb-item1 a {
	color: #76839a;
}
.breadcrumb-item2 a {
	color: #76839a;
}

.panel-title1 a {
	background: $primary-01 !important;
}
.btn.dropdown-toggle.btn-primary~.dropdown-menu .dropdown-plus-title {
	border-color: $primary-1 !important;
}
.panel-default>.panel-heading {
	color: $primary-1;
}
.alert-primary {
	color: $primary-1;
	background-color: $primary-01;
	border-color: rgba(98, 89, 202, 0.01);
	hr {
		border-top-color: $primary-1;
	}
	.alert-link {
		color: $primary-1;
	}
}
.list-group-item-primary {
	color: $primary-1;
	background-color: #cbdbf2;
	&.list-group-item-action {
		&:hover, &:focus {
			color: $primary-1;
			background-color: #b7cded;
		}
		&.active {
			color: $white;
			background-color: $primary-1;
			border-color: $primary-1;
		}
	}
}
.header-bg {
	background: $primary-1;
	/* fallback for old browsers */
	background: -webkit-$primary-gradient-1 !important;
	/* Chrome 10-25, Safari 5.1-6 */
	background: $primary-gradient-1 !important;
}
.footer a:not(.btn) {
	color: $primary-1;
}
.nav-tabs .nav-link {
	&:hover:not(.disabled), &.active {
		background: $primary-1;
	}
}
@media (max-width: 992px) {
	.nav-item.with-sub .sub-item {
		border-top: 2px solid $primary-1;
	}
}
.expanel-primary>.expanel-heading {
	color: $white !important;
	background-color: $primary-1 !important;
	border-color: $primary-1 !important;
}
.login-img {
	background: $primary-1;
}
body.dark-mode {
	&.login-img {
		background: $primary-1;
	}
}
.avatar {
	background: #e3e1f5 no-repeat center/cover;
}
.spinner, .spinner-lg, .double-bounce1, .double-bounce2, .cube1, .cube2 {
	background-color: $primary-1;
}
.lds-heart div {
	background: $primary-1;
	&:after, &:before {
		background: $primary-1;
	}
}
.lds-ring div {
	border: 6px solid $primary-1;
	border-color: $primary-1 transparent transparent transparent;
}
.lds-hourglass:after {
	border: 26px solid $primary-1;
	border-color: $primary-1 transparent;
}
a.chip:hover {
	background-color: $primary-1;
}
.tag-primary {
	background-color: $primary-1;
	color: $white;
}
.selectgroup-input {
	&:checked+.selectgroup-button {
		border-color: $primary-1;
		z-index: 1;
		color: $primary-1;
		background: $primary-01;
	}
	&:focus+.selectgroup-button {
		border-color: $primary-1;
		z-index: 2;
		color: $primary-1;
		box-shadow: 0 0 0 2px $primary-02;
	}
}
.custom-switch-input:checked~.custom-switch-indicator {
	background: $primary-1;
}
.ui-datepicker .ui-datepicker-title {
	color: $primary-1;
}
.timeline--horizontal .timeline-divider {
	background: $primary-1 !important;
}
#back-to-top {
	background-image: $primary-gradient-1;
	&:hover {
		color: $primary-1 !important;
		border: 2px solid $primary-1;
	}
}
.tabs-menu1 ul li .active {
	border-bottom: 3px solid $primary-1;
}
.tab-content i, .tabs-menu2 ul li .active {
	color: $primary-1;
}
.tab-content .btn i{
	color:$white;
}
.rating-stars .rating-stars-container .rating-star {
	&.is--active .fa-heart, &.is--hover .fa-heart {
		color: $primary-1;
	}
}
.message-feed:not(.right) .mf-content {
	background: $primary-1;
	color: $white;
	&:before {
		border-right-color: $primary-1;
	}
}
.msb-reply button {
	background: $primary-1;
}
/* --------Added--------- css*/

.wizard-card .moving-tab {
	margin-top: 5px;
	background: $primary-gradient-1;
}
.cal1 .clndr {
	.clndr-table {
		.header-days {
			background: $white;
		}
		tr .day {
			&.today.event, &.my-today.event {
				background: $primary-1;
			}
		}
	}
	.clndr-controls .clndr-control-button {
		.clndr-previous-button {
			color: $white;
			background: $primary-1;
			border-radius: 5px;
		}
		.clndr-next-button {
			color: $white;
			background: $primary-1;
			border-radius: 5px;
			&:hover {
				background: $primary-1;
			}
		}
		.clndr-previous-button:hover {
			background: $primary-1;
		}
	}
}
.fc button {
	background: $primary-1;
}
.fc-event {
	background-color: transparent;
}
.fc-event-dot {
	background-color: $primary-1;
}
/* ----Added css----*/

div.conv-form-wrapper div.options div.option {
	color: $primary-1;
	border: 1px solid $primary-1;
}
form.convFormDynamic button.submit {
	color: $primary-1;
	border: 1px solid $primary-1 !important;
}
div.conv-form-wrapper div.options div.option.selected {
	background: $primary-1;
	color: $white;
	&:hover {
		background: $primary-1;
	}
}
form.convFormDynamic button.submit:hover {
	background: $primary-1 !important;
	color: $white;
}
div.conv-form-wrapper div#messages div.message.from {
	background: $primary-1;
}
.addui-slider .addui-slider-track {
	.addui-slider-handle:after, .addui-slider-range {
		background: $primary-1;
	}
}
.timeline__item:after {
	border: 6px solid $primary-1;
}
.accordionjs .acc_section.acc_active>.acc_head {
	background: $primary-01;
	color:  $primary-1 !important;
}
.tab_wrapper {
	>ul li.active {
		border-color: $primary-1;
		background: $primary-1;
	}
	&.right_side>ul li.active:after {
		background: $primary-1;
	}
}
.cal1 .clndr .clndr-table tr .day {
	&.today, &.my-today {
		background: $white;
		color: $dark;
	}
	&.today:hover, &.my-today:hover {
		background: $primary-1;
		color: $white;
	}
}
.weather-card .top {
	background: $primary-1;
}
.primary .pricing-divider {
	background: $primary-1 !important;
}
.product-grid6 {
	.title a:hover {
		color: $primary-1;
	}
	.icons li .btn {
		color: #6e84a3;
		border: 1px solid $border;
		&:hover {
			color: $white;
			background-image: $primary-gradient-1;
		}
		&:after, &:before {
			background: $primary-1;
		}
	}
}
.card-counter.primary {
	background-image: $primary-gradient-1;
	color: $white;
}
#user-profile .profile-details {
	a>i, i {
		color: $primary-1;
	}
}
@media (max-width: 767.98px) and (min-width: 576px) {
	.search-show .search-element {
		background-image: $primary-gradient-1;
	}
}
@media (max-width: 575.98px) {
	.search-show .search-element {
		background-image: $primary-gradient-1;
	}
}
.text-primary-gradient {
	background: $primary-gradient-1;
}
.chat .msg_head, .msb-reply button {
	background: $primary-1;
}
.top-footer {
	p:hover {
		color: $primary-1;
	}
	a {
		address:hover, &:hover {
			color: $primary-1;
		}
	}
}
.footer-payments a:hover {
	color: $primary-1;
}
.footer-social-list a {
	color: $primary-1;
	&:hover {
		color: $primary-1;
	}
}
.sw-theme-dots .nav-tabs .nav-link:hover:not(.disabled) {
	color: $primary-1;
}
.custom-switch-input:checked~.custom-switch-indicator {
	background: $primary-1;
}
.label-primary {
	background: $primary-1;
	color: $white;
}
.bg-primary-transparent {
	background-color: $primary-02 !important;
}
.text-primary-shadow {
	text-shadow: 0 5px 10px $primary-03;
}
.chart-dropshadow-primary {
	-webkit-filter: drop-shadow((-6px) 12px 4px $primary-02);
	filter: drop-shadow((-6px) 12px 4px  $primary-02);
}
.fc-toolbar {
	.fc-state-active, .ui-state-active {
		background: $primary-1;
	}
}
/*-- Sidemenu --*/

.side-menu__item {
	&.active{
		color: $white;
	}
	&.active .side-menu__icon {
		color: $white !important;
	}
}

/*-- Horizontal-menu --*/

.horizontalMenu>.horizontalMenu-list>li> {
	ul.sub-menu>li> {
		a:hover, ul.sub-menu>li>a:hover {
			color: $primary-1;
		}
	}
}
.onoffswitch-checkbox:checked+.onoffswitch-label {
	background-color: $primary-1;
	border-color: $primary-1;
	&:before {
		border-color: $primary-1;
	}
}
.onoffswitch2-checkbox:checked+ {
	.onoffswitch2-label {
		background-color: $primary-1;
	}
	.onoffswitch-label2, .onoffswitch2-label:before {
		border-color: $primary-1;
	}
}
/*--Dark-mode---*/

.dropdown-item:focus, .dark-mode .dropdown-item.active, .dropdown-item:active {
	color: $primary-1;
}
.dark-mode {
	.side-menu__item.active {
		color: $white !important;
	}
}


.dark-mode .side-menu__item {
	&.active{
		color: $white;
	}
	&.active .side-menu__icon {
		color: $white;
	}
}
/*Color-Header*/

.light-menu {
	&.dark-mode .side-menu__item {
		&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon, &.active, &:hover, &:focus {
			color: #8061ce;
		}
	}
	.side-menu__item {
		&.active .side-menu__icon {
			color: $white;
		}
		&:hover .side-menu__icon, &:focus .side-menu__icon, &.active, &:hover, &:focus{
			color: $primary-1 !important;
		}
	}
}
.default-body .app-sidebar {
	background: $primary-1 !important;
}
/*Color-Header*/

.horizontal-main2.horizontal-main {
	background: $primary-1;
}
@media only screen and (max-width: 991px) {
	.dark-mode .horizontalMenu>.horizontalMenu-list {
		background: #30304d;
	}
}
.dark-menu .side-menu__item {
	&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon, &.active, &:hover {
		color: #8c63f3;
	}
}
.color-menu {
	.app-sidebar {
		background: $primary-1;
	}
	.side-header {
		background: $primary-1;
		// border-bottom: 1px solid #7d76c5;
	}
	.side-menu__item {
		&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon, &.active, &:hover, &:focus {
			color: $white !important;
		}
	}
	.side-menu h3 {
		color: $white-5 !important;
	}
	.side-menu .side-menu__icon, .side-menu .side-menu__item{
		color: $white-8 !important;
	}
	.side-menu__item:hover, .side-menu__item:focus {
		color: $white !important;
		background: rgba(0, 0, 0, 0.05) !important;
	}
	.side-menu__item:hover .side-menu__icon, .side-menu__item:hover .side-menu__label, .side-menu__item:focus .side-menu__icon, .side-menu__item:focus .side-menu__label {
		color: $white !important;
	}
	&.sidebar-mini .slide a {
		color: $white-8 !important;
	}
	&.sidebar-mini .slide-item {
		color: $white-8;
	}
	&.sidebar-mini .slide-menu li .slide-item:before {
		color: $white-8;
	}
	.side-menu__item {
		color: $white-8;
	}
	.slide-menu a.active{
		color: $white !important;
	}
}
.gradient-menu .side-menu__item {
	&.active .side-menu__icon, &:hover .side-menu__icon, &:focus .side-menu__icon, &.active, &:hover, &:focus {
		color: #ccc9ec !important;
	}
}
.gradient-menu{
	.side-menu__item:hover .side-menu__icon, .side-menu__item:hover .side-menu__label, .side-menu__item:focus .side-menu__icon, .side-menu__item:focus .side-menu__label {
		color: #ccc9ec;
	}
}
.color-menu.sidemenu-bgimage {
	.side-header:before, .app-sidebar:before {
		background: rgba(98, 89, 202, 0.94);
	}
}
@media (max-width: 767px) {
	.color-menu .mobile-header {
		background: $primary-1 !important;
	}
}